import { useCallback } from "react";

import COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/COMMON_QUERY";
import { UserLiner } from "@sellernote/shared/src/types/common/common";

const SHIPDA_LOGO_URL =
  "https://sellernote.s3.ap-northeast-2.amazonaws.com/images/liner/sea/SHIPDA.webp";

/** 선사에 쉽다를 표기할 때의 정보 (선사 API 응답에 포함하지 않기로 함) */
const SHIPDA_LINER_INFO: UserLiner = {
  id: 0, // 임시 값
  name: "SHIPDA",
  nameEN: "SHIPDA",
  alias: "SHIPDA",
  logoUrl: SHIPDA_LOGO_URL,
  type: "sea",
  MBLprefix: "SHIPDA",
  nameDisplay: "SHIPDA",
};

/**
 * 선사(이하 항공사도 포함하여 선사라 칭함)) 정보를 조회할 수 있는 `getLinerInfo` 함수를 제공하는 hook
 * - 선사조회 API([GET] /liners) 응답을 조합하여 선사정보를 반환한다
 */
export default function useGetLinerInfo() {
  const { data: linerList } = COMMON_QUERY.useGetUserLinerList({});

  /**
   * 선사 ID 값으로 선사 정보를 찾는 유틸함수
   *
   * @param linerId - 선사 정보를 찾고자 하는 선사의 ID입니다.
   * @returns 주어진 선사 ID에 해당하는 선사의 정보를 반환합니다. 만약 해당 선사를 찾지 못하면 undefined를 반환합니다.
   */
  const getLinerInfo = useCallback(
    ({ linerId, freightType }: { linerId?: number; freightType?: string }) => {
      if (!linerList) return;

      const target = (() => {
        // LCL 이면서 linerId 가 null 인 경우, 쉽다 표기
        if (freightType === "LCL" && linerId === null) return SHIPDA_LINER_INFO;

        const liner = linerList.find((liner) => liner.id === linerId);

        return liner;
      })();

      return {
        ...target,
        /**
         * 화면에 표시하는 선사 이름
         * - alias도 영어이므로 번역 신경쓸 필요 없음
         * - 기존 응답인터페이스에 한국어/영문일때 쓸만한 필드가 있긴한데, 언젠가부터 유지보수 되지 않고 있어서 일단 이 값들로 표시한다 (참고: https://sellernotehq.slack.com/archives/C04MQ2T33MG/p1734937312532039)
         **/
        label: target?.alias ?? target?.nameEN,
      };
    },
    [linerList]
  );

  return { getLinerInfo };
}
